<template>
  <div>
    <b-row>
      <b-col md="12">
      </b-col>
       <b-col class="resource-loading-filtro" md="12" v-if="!isEmpty(listagemFiltros.workcenter) || !isEmpty(listagemFiltros.resource) || (listagemFiltros.date.dataInicio)">
        <button type="button" @click="clearFilters" class="limpar-filtros-btn d-none d-sm-block">{{$t('ResourceLoading.Clean')}}</button>
        <div class="filtros-area">
          <div class="filtro-item" v-if="listagemFiltros.date && listagemFiltros.date.dataInicio && listagemFiltros.date.dataFim" >
            {{ getFormattedDate() }}
            <imgDeleteIcon @click="deleteFilteredItem('date')" />
          </div>
          <div class="filtro-item" v-for="workcenter in listagemFiltros.workcenter" :key="'workcenter-filter-item' + workcenter.id">
            {{workcenter.name}}<imgDeleteIcon @click="deleteFilteredItem('workcenter', workcenter.id)" />
          </div>
          <div class="filtro-item"  v-for="resource in listagemFiltros.resource" :key="'resource-filter-item' + resource.id">
            {{resource.name}}<imgDeleteIcon @click="deleteFilteredItem('resource', resource.id)" />
          </div>
        </div>
      </b-col> 
    </b-row>
    <b-row>
      <b-col lg="8" sm="12">
        <b-row>
          <b-col md="4">
            <b-card class="card-capacidade-utilizada">
              <div class="infos-card ">
                <div class="header-card">
                    <span class="title">{{`${$t('ScheduleAnalysis.Used_Capacity')}`}}</span>
                    <imgTooltipIcon id="tooltip-capacidade-utilizada"/>
                    <b-tooltip
                      custom-class="tooltip-schedule-analysis"
                      target="tooltip-capacidade-utilizada"
                      triggers="hover"
                      positioning="bottom"
                      placement="bottom"
                  >
                      <div class="graph-percent">
                        <div class="info-tooltip-schedule-analysis">
                         {{`${$t('ScheduleAnalysis.tooltip')}`}}
                        </div>
                      </div>
                  </b-tooltip>
                </div>
                <div class="percent-graph capacidade">
                  <vc-donut 
                    :size="180"
                    unit="px" 
                    :thickness="25"
                    :foreground="`${parseFloat(this.utilization) == 100 ? '#ffff' :'#F4EEE8'}`"
                    :sections="[{ value: this.utilization !== null ? parseFloat(this.utilization)/2 : 0, color: '#FF8D2E'  }]"
                    :start-angle="-90"
                    >{{ this.utilization !== null ? parseFloat(this.utilization).toFixed(0) : '0' }}%
                  </vc-donut>
                  
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col md="8">
            <b-card class="card-capacidade-utilizada">
              <div class="infos-card">
                <div class="header-card">
                    <span class="title">{{`${$t('ScheduleAnalysis.Scheduling_by_Status')}`}}</span>
                </div>
                <b-row class="row-graph" >
                  <b-col v-for="(status,index) in statusList" :key="index" md="3" class="item-graph mb-2 mb-sm-0"> 
                    <div class="status-graph">
                      <vc-donut 
                        :size="45"
                        unit="px" 
                        :thickness="15"
                        foreground="#F4EEE8"
                        :sections="[{ value: parseInt(status.value), 
                        color:` ${status.name == 'working' ? '#FFA726'
                              : status.name == 'idle' ? '#42A5F5'
                              : status.name == 'unavailable' ? '#EF5350'
                              : '#78909C' }` }]"
                        >
                          {{ status.value !== null ? parseFloat(status.value).toFixed(0) : '- '}}%
                      </vc-donut>
                    </div>
              
                    <div class="legend">
                      <div 
                        class="circle-legend" 
                        :style="{
                          '--colorLegend': ( status.name == 'working' ? '#FFA726'
                                            : status.name == 'idle' ? '#42A5F5'
                                            : status.name == 'unavailable' ? '#EF5350'
                                            : status.name == 'setup' ? '#78909C'
                                            : '#ccc'
                        )}"
                      />
                      <span class="info-legend">{{ status.name == 'working' ? 'Ocupado'
                                                  : status.name == 'idle' ? 'Livre'
                                                  : status.name == 'unavailable' ? 'Indisponível'
                                                  : status.name == 'setup' ? 'Setup'
                                                  : ''
                      }}
                      </span>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
          <b-col md="12">
            <b-card class="card-capacidade-utilizada">
              <div class="infos-card">
                <div class="header-card">
                    <span class="title">{{`${$t('ScheduleAnalysis.Scheduling_by_Day')}`}}</span>
                </div>
                <div class="legends">
                  <div class="legend-item">
                    <div class="circle-legend" :style="{'--colorLegend': '#78909C'}"></div>
                    <span class="info-legend">Setup</span>
                  </div>
                  <div class="legend-item">
                    <div class="circle-legend" :style="{'--colorLegend': '#FFA726'}"></div>
                    <span class="info-legend">Ocupado</span>
                  </div>
                  <div class="legend-item">
                    <div class="circle-legend" :style="{'--colorLegend': '#EF5350'}"></div>
                    <span class="info-legend">Indisponível</span>
                  </div>
                  <div class="legend-item">
                    <div class="circle-legend" :style="{'--colorLegend': '#42A5F5'}"></div>
                    <span class="info-legend">Livre</span>
                  </div>
                </div>
                <div>
                    <ag-charts-vue :options="options" :key="keyControlRenderChart"></ag-charts-vue>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      
      
        <b-col lg="4" sm="12">
          <div class="recursos">
            <div class="infos-card">
              <div class="header-card">
                  <span class="title">{{`${$t('ScheduleAnalysis.Resources')}`}}</span>
              </div>
              <div class="listagem-recursos">
                <div v-for="(recurso,index) in resourcesList" :key="index" class="item-recurso">
                  <div class="title-status">
                    <span class="resource-title">{{recurso.resource}}</span>
                    <div 
                      class="status-atual" 
                      :style="`background-color:${recurso.last_status.color + '26'};`"
                    >
                      <span :style="`color:${recurso.last_status.color};`">{{recurso.last_status.name}}</span>
                    </div>
                  </div>
                  <div class="color-bar">
                    <div class="bar" 
                      v-for="(status, index) in sortList(resourcesList[index].status)"
                      v-if="status.value > 0" 
                      :key="index" 
                      :style="`
                        width:${(parseFloat(status.value) * 100)}%;
                        background-color:${status.name == 'working' ? '#FFA726'
                                          : status.name == 'idle' ? '#42A5F5'
                                          : status.name == 'unavailable' ? '#EF5350'
                                          : status.name == 'setup' ? '#78909C'
                                          : '#ccc'
                        };
                        `"
                        
                      >
                      <span class="percent-status" :style="`padding-right:${status.value < 15 ? '5px' : 0}`">{{parseFloat(status.value).toFixed(0)}}%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-col>
    </b-row>
  </div>
</template>

<script>
import imgTooltipIcon from "@/assets/images/pages/tooltip-icon.svg";
import imgDeleteIcon from '@/assets/images/icons/delete-icon.svg'
import FilterDays from '@/@core/components/filter-days/FilterDays.vue'
import { AgChartsVue } from 'ag-charts-vue';
import moment from "moment";
import { mapMutations } from "vuex";
import { 
  BCard, 
  BCardText, 
  BLink ,
  BCardGroup,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BProgress,
  BButton,
  BTooltip,
  } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BCardGroup,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BProgress,
    BButton,
    BTooltip,
    imgTooltipIcon,
    imgDeleteIcon,
    FilterDays,
    AgChartsVue
  },
  data(){
    return {
      keyControlRenderChart: 0,
      options: null,
      listagemFiltros: {
        workcenter: [],
        resource: [],
        date: {
          dataInicio: '',
          dataFim: ''
        }
      },
      urlResourcesLoading:'',
      resourcesList:[],
      statusList: [],
      utilization: null,
      currentSite: '',
      dadosGrafico:[]
    }
  },
   created(){
    this.currentSite = localStorage.getItem('currentSite');
    this.getDataScheduleAnalysis();
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    createChart(){

        this.options = {
        data: this.dadosGrafico,
        series: [
          {
            type: 'column',
            xKey: 'label',
            yKey: ['working', 'setup','unavailable','idle'],
            stacked: true,
            fill:[ '#FFA726', '#78909C', '#EF5350', '#42A5F5'],
            stroke:[ '#FFA726', '#78909C', '#EF5350', '#42A5F5'],
            highlightStyle: {
              item: {
                fill: 'transparent',
                stroke: 'transparent',
                strokeWidth: 0,
              },
            },
            tooltip: {
              renderer: function (params) {
                  return `
                  
                  <div class="ag-chart-tooltip-content"> 
                    <div class="square-tooltip" style="background-color: ${params.color}"></div>
                    ${params.yKey == 'setup' ? 'Setup' : params.yKey == 'unavailable' ? 'Indisponível' : params.yKey == 'idle' ? 'Livre' : 'Ocupado'} : ${params.yValue}%
                  </div>`
              }
            }
          },
        ],
        legend: {
          enabled: false,
        },
        navigator: {
          height: 8,
          min: 0.1,
          max: 0.9,
          mask: {
            fill: '#FBEEE8',
            strokeWidth: 0.5,
            fillOpacity: 1,
          },
          minHandle: {
             stroke: '#CFC4BE',
            width: 12,
            height: 15,
            strokeWidth: 1,
          },
          maxHandle: {
             stroke: '#CFC4BE',
             width: 12,
            height: 15,
            strokeWidth: 1,
          },
        },
      }

    },
     getDataScheduleAnalysis() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/schedule-analysis/dashboards?site_guid=${this.currentSite + this.urlResourcesLoading}`)
      .then(res => {
        this.utilization = res.data.response.schedule_analysis_global[0].utilization;
        this.resourcesList = res.data.response.schedule_analysis_by_resource;
        this.statusList = res.data.response.schedule_analysis_by_status;

        this.dadosGrafico = res.data.response.schedule_analysis_by_reference_date.map(item => {
          return{
            label: moment(item.reference_date).format("DD/MM"),
            working: parseInt(item.working),
            idle: parseInt(item.idle),
            unavailable: parseInt(item.unavailable),
            setup: parseInt(item.setup)
          }
        });
        

      }).then(() => {

        this.createChart();
        this.keyControlRenderChart += 1;
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        
      }).catch(error => {
        console.log(error)
      });
      

    },
    isEmpty(obj) { // for ES3 and older
      for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
              return false;
      }
      return true;
    },
    clearFilters() {
      this.listagemFiltros = {
        workcenter: [],
        resource: [],
        date: {
          dataInicio: '',
          dataFim: ''
        }
      }
      this.verificaURL();
    },
    verificaURL(){
      this.urlResourcesLoading =  `&filterDays=7`

      if (this.listagemFiltros.workcenter.length > 0){
          
          this.urlResourcesLoading += `&workcenters_ids=${this.listagemFiltros.workcenter.map(item => item.id)}`
      }
      if (this.listagemFiltros.resource.length > 0){
          this.urlResourcesLoading += `&resources_ids=${this.listagemFiltros.resource.map(item => item.id)}`
      }
      if(this.listagemFiltros.date.dataInicio){
          this.urlResourcesLoading += `&start_date=${this.listagemFiltros.date.dataInicio}`
      }
      if(this.listagemFiltros.date.dataFim){
          this.urlResourcesLoading += `&end_date=${this.listagemFiltros.date.dataFim}`
      }
      this.getDataScheduleAnalysis();
    },
    deleteFilteredItem(type = null, id = null) {
      if(type == 'workcenter') {
        this.listagemFiltros.workcenter.splice(
          this.listagemFiltros.workcenter.indexOf(
            this.listagemFiltros.workcenter.find((item) => item.id == id)), 1)
      } else if(type == 'resource') {
        this.listagemFiltros.resource.splice(
          this.listagemFiltros.resource.indexOf(
            this.listagemFiltros.resource.find((item) => item.id == id)), 1)
      } else {
        this.listagemFiltros.date.dataInicio = ''
        this.listagemFiltros.date.dataFim = ''
      }
      this.verificaURL();
    },
    sortList(statusList) {
      let setup = statusList.find(x => x.name === 'setup');
      let working = statusList.find(x => x.name === 'working');
      let unavailable = statusList.find(x => x.name === 'unavailable');
      let idle = statusList.find(x => x.name === 'idle');

      let array = [setup, working, unavailable, idle];

      return array;
    },
    getFormattedDate() {
      let formatedDate = '';

      if (localStorage.getItem('language') == 'pt-BR')
          formatedDate = `${moment(this.listagemFiltros.date.dataInicio).format('DD/MM/YY')} ${this.$t('Operator.Until')} ${moment(this.listagemFiltros.date.dataFim).format('DD/MM/YY')}`;
      else if (localStorage.getItem('language') == 'en-US')
        formatedDate = `${moment(this.listagemFiltros.date.dataInicio).format('MM/DD/YY')} ${this.$t('Operator.Until')} ${moment(this.listagemFiltros.date.dataFim).format('MM/DD/YY')}`;

      return formatedDate;
    }
  } 
}
</script>

<style lang="scss">
.overlay-mensage{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 575.98px) {
  .tooltip-inner {
    max-width: 288px !important;
  }
}

.ag-chart-tooltip-content {
  background-color: #362F2B;
  color:#ffff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  .square-tooltip{
    width: 14px !important;
    height: 14px !important;
    margin-right: 8px;
  }
}


.card-capacidade-utilizada{
  margin-bottom: 24px;
  min-height: 146px;
  .card-body{
    padding: 16px;
    min-height: 158px;
    .infos-card{
      .header-card{
        display: flex;
        justify-content: space-between;
        .title{
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #4C4541;
        }
      }
      .legends{
        display: flex;
        margin-top: 8px;
        .legend-item{
          display: flex;
          align-items: center;
          margin-right: 14px;
          .circle-legend{
            border-radius: 50%;
            width: 10px !important;
            height: 10px !important;
            background-color: var(--colorLegend);
            margin-right: 4px;
          }
          .info-legend{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998F8A;
          }
        }
      }

    }
  }
}
.recursos{
  .infos-card{
    .header-card{
      margin-bottom: 8px;
      .title{
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #4C4541;
      }
    }
    .listagem-recursos{
      height: 619px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;               /* width of the entire scrollbar */
        border-radius: 50%;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #FBEEE8;        /* color of the tracking area */
        margin-bottom: 16px;
        margin-top: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #CFC4BE;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
      }
      .item-recurso{
        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        border-radius: 6px;
        padding: 16px;
        margin-bottom: 16px;
        margin-right: 7px;
        .title-status{
          display: flex;
          justify-content: space-between;
          .resource-title{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4C4541;
          }
          .status-atual{
            border-radius: 4px;
            padding: 1px 9px;
            span{
              font-weight: 600;
              font-size: 12px;
            }
          }
          
        }
        .color-bar{
          margin-top: 12px;
          display: flex;
          :first-child{
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
          }
          :last-child{
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          .bar{
            height: 24px;
            display: flex;
            align-items: center;
            .percent-status{
              color: #FFFFFF;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              margin-left: 16px;
            }
          }
          
        }
      }
    }
  }
}

.tooltip-schedule-analysis {
  //não adicione padding aqui e só estilize as classes especificas se possível
  margin: 0;
  .tooltip-inner {
    padding: 0;
    max-width: 100%;
    min-width: 1%;
    border-radius: 6px;
    overflow: hidden;
    .graph-percent{
      padding: 8px 8px;
      max-width: 401px;
      .info-tooltip-schedule-analysis {
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        letter-spacing: 0.16px;
      }
    }
  }

  .arrow {
      &::before {
          border-top-color: #323232;
          border-bottom-color: #323232;
      }
  }
}

.percent-graph.capacidade{
  margin-bottom: 4px !important;
  margin-top: 6px !important;
  margin: auto;
  width:180px;
  height:91px;
  background-color:#fff;
  overflow: hidden;
  .cdc-text{
    font-weight: 600;
    font-size: 22px !important;
    line-height: 30px;
    color: #4C4541;
    margin-bottom: 30px;
  }  
}

.status-graph{
  margin-bottom: 9px;
  .cdc-text{
    font-weight: 600;
    font-size: 12px !important;
    line-height: 16px;
    color: #4C4541;
  }
}

</style>

<style lang="scss" scoped>

.row-graph{
  margin-top: 14px;
  display: flex;
  justify-content: space-around;
  .item-graph{
    display: flex;
    align-items: center;
    flex-direction: column;
    .legend{
      display: flex;
      align-items: center;
      .circle-legend{
        border-radius: 50%;
        width: 10px !important;
        height: 10px !important;
        background-color: var(--colorLegend);
        margin-right: 4px;
      }
      .info-legend{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #998F8A;
      }
    }
  }
}

.resource-loading-filtro {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .limpar-filtros-btn {
    background: #974900;
    border-radius: 5px;
    white-space: nowrap;
    height: fit-content;
    border: 1px solid #974900;
    padding: 3px 14px;
    color: #fff;
    margin-right: 16px;
    font: 600 12px/20px initial;
    &:hover {
      background: #F7F3EF;
      color: #974900;
    }
  }
  .filtros-area {
    display: flex;
    overflow: auto;
    padding-right: 28px;
    margin-right: -28px;
    &::-webkit-scrollbar {
      height: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #FBEEE8;        /* color of the tracking area */
      margin-right: 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CFC4BE;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
    .filtro-item {
      display: flex;
      font: 600 12px/20px initial;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      padding: 6px 14px;
      margin: 0 5px;
      background-color: #FFDBC4;
      color: #974900;
      border-radius: 8px;
      svg {
        margin-left: 12px;
      }
      &:hover {
          cursor: pointer;
        }
    }
  }
}

</style>